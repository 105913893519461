import {ControlValueAccessor} from '@angular/forms';

export class ControlWrapper implements ControlValueAccessor {

  public writeValue(obj: any): void {
  }

  public registerOnChange(fn: any): void {
  }

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
  }

}
