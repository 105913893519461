import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  public message: string = 'pages.notFound.generic';
  public payload!: object;

  public ngOnInit(): void {
    const {currentUrl} = window.history.state;
    if (currentUrl) {
      this.message = 'pages.notFound.specific';
      this.payload = {url: currentUrl};
    }
  }

}
