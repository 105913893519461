import {Observable} from 'rxjs';
import {FactoryProvider} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {ILoginPayload} from './ILoginPayload';
import {ILogin2ndStepPayload} from './ILogin2ndStepPayload';
import {ILoginResponse} from './ILoginResponse';

export class Auth {

  constructor(private http: HttpClient) {
  }

  public login(data: ILoginPayload): Observable<HttpResponse<ILoginResponse>> {
    return this.http.post<ILoginResponse>(`${environment.apiUrl}/login`, data, {observe: 'response'});
  }

  public login2ndStep(data: ILogin2ndStepPayload): Observable<HttpResponse<ILoginResponse>> {
    return this.http.post<ILoginResponse>(`${environment.apiUrl}/login-2nd-step`, data, {observe: 'response'});
  }

  public logout(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/logout`);
  }

}

export const authProvider: FactoryProvider = {
  provide: Auth,
  useFactory: (http: HttpClient) => new Auth(http),
  deps: [HttpClient]
};
