import {NgModule} from '@angular/core';
import {SafePipe} from '@core/pipes/safe.pipe';

@NgModule({
  declarations: [
    SafePipe
  ],
  exports: [
    SafePipe
  ]
})
export class PipesModule {
}
