import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import {Profile} from "@core/providers/profile/profileProvider";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private profile: Profile,
    private router: Router
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.profile.get().pipe(
      map(user => {
        if (route.data?.role && route.data?.role.indexOf(user.role) === -1) {
          this.router.navigate(['/home']).then();
          return false;
        }
        return true;
      }),
      catchError(_ => {
        this.router.navigate(['/login']).then();
        return of(false);
      })
    );
  }

}
