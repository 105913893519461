import {AbstractControl, FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {IServerErrorReason} from '../interfaces/IServerErrorReason';
import {removeControlError} from './removeControlError';
import {FormControls} from "@core/interfaces/FormControls";
import {FormValue} from "@core/interfaces/FormValue";
import {Observable} from "rxjs";

export interface IControlError {
  control: AbstractControl;
  errorMessage: string;
}

export class AppFormGroup<C extends FormControls> extends FormGroup {

  public readonly value!: FormValue<C>;
  public readonly valueChanges!: Observable<FormValue<C>>;
  public controls!: C;

  private controlToElementMap: Map<AbstractControl, HTMLElement> = new Map<AbstractControl, HTMLElement>();

  public registerControlHtmlElement(control: AbstractControl, element: HTMLElement): void {
    this.controlToElementMap.set(control, element);
  }

  public handleError(error: HttpErrorResponse): void {
    const controls = Array.from(this.controlToElementMap.keys());
    controls.forEach(c => removeControlError('serverValidation', c));
    const errors = (error.error.errors || []) as IServerErrorReason[];
    const controlsError = this.getControlsError(errors).filter(e => !!e);
    for (const e of controlsError) {
      const {control, errorMessage} = e;
      control.setErrors({
        serverValidation: {message: errorMessage}
      });
      control.markAsTouched();
    }
    this.scrollToError();
  }

  protected getControlsError(errors: IServerErrorReason[]): IControlError[] {
    return [];
  }

  public showErrors(): void {
    this.markAllAsTouched();
    this.scrollToError();
  }

  public scrollToError(): void {
    const invalidControl = Array.from(this.controlToElementMap.keys()).find(c => c.invalid);
    if (invalidControl) {
      const element = this.controlToElementMap.get(invalidControl);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }

  public dispose(): void {
    this.controlToElementMap.clear();
  }

}
