import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControlComponent} from './form-control.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ValidationErrorModule} from '../validation-error/validation-error.module';
import {RequiredStarModule} from '@core/components/required-star/required-star.module';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ValidationErrorModule,
        RequiredStarModule,
        TranslateModule
    ],
  declarations: [
    FormControlComponent
  ],
  exports: [
    FormControlComponent
  ]
})
export class FormControlModule {
}
