import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Auth} from '@core/providers/auth/authProvider';
import {FormSubmitter} from '@core/misc/FormSubmitter';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {genericErrorMessage} from '@core/helpers/genericError';
import {ILoginResponse} from '@core/providers/auth/ILoginResponse';
import {HttpResponse} from '@angular/common/http';
import {ILogin2ndStepPayload} from "@core/providers/auth/ILogin2ndStepPayload";
import {LoginFormGroup} from "../LoginFormGroup";

@Component({
  selector: 'login-second-step',
  templateUrl: './login-second-step.component.html',
  styleUrls: ['./login-second-step.component.scss']
})
export class LoginSecondStepComponent implements OnInit, OnDestroy {

  public submitter!: FormSubmitter<ILogin2ndStepPayload, HttpResponse<ILoginResponse>>;
  public loginError!: Observable<string>;

  @Output()
  public readonly successEvent = new EventEmitter<HttpResponse<ILoginResponse>>();

  @Output()
  public readonly backEvent = new EventEmitter<any>();

  @Input()
  public form!: LoginFormGroup;

  constructor(
    private router: Router,
    private api: Auth
  ) {
  }

  public ngOnInit(): void {
    this.submitter = new FormSubmitter({
      action: p => this.api.login2ndStep(p),
      form: this.form
    });
    this.submitter.success$.subscribe(r => this.successEvent.emit(r));
    this.loginError = this.submitter.error$.pipe(
      filter(e => !!e),
      map(e => {
        if (e.status === 401) {
          return 'pages.login.errors.wrongCode';
        } else {
          return genericErrorMessage;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.submitter.dispose();
  }

  public onCodeCompleted(code: string) {
    this.submitter.submit({...this.form.value, code});
  }

}
