import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RequiredStarComponent} from './required-star.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    RequiredStarComponent
  ],
  exports: [RequiredStarComponent]
})
export class RequiredStarModule {
}
