<h4 mat-dialog-title>
  {{data.title | translate}}
</h4>
<div mat-dialog-content
     class="text-break"
     [innerHTML]="data.message | translate | safe: 'html'">
</div>
<mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="dialogRef.close()">
    {{'buttons.close' | translate}}
  </button>
</mat-dialog-actions>

