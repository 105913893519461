import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '@core/services/auth.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    let headers = req.headers;
    let request;
    if (token) {
      headers = headers.set('X-Auth-Token', token.auth as string);
      if (token.remember) {
        headers = headers.set('X-REMEMBER-ME', token.remember);
      }
    }
    request = req.clone({headers});
    return next.handle(request).pipe(
      tap((httpEvent: HttpEvent<any>) => {
        if (httpEvent.type === 0) {
          return;
        }
        if ((httpEvent as any) instanceof HttpResponse) {
          const response = httpEvent as HttpResponse<any>;
          if (response.headers.has('X-Auth-Token')) {
            this.auth.setToken(response.headers.get('X-Auth-Token') as string);
          }
        }
      })
    );
  }

}

