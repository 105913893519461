import {Component} from '@angular/core';
import {LoginFormGroup} from './LoginFormGroup';
import {ILoginResponse} from "@core/providers/auth/ILoginResponse";
import {Router} from "@angular/router";
import {Auth} from "@core/providers/auth/authProvider";
import {AuthService} from "@core/services/auth.service";
import {HttpResponse} from "@angular/common/http";

enum Step {
  LOGIN,
  SMS_VERIFICATION
}

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public step: Step = Step.LOGIN;

  public readonly currentYear = new Date().getFullYear();

  public readonly form: LoginFormGroup;

  constructor(
    private router: Router,
    private api: Auth,
    private auth: AuthService
  ) {
    this.form = new LoginFormGroup();
  }

  public onStepSubmit(r: HttpResponse<ILoginResponse>): void {
    if (r.body!.requireVerificationCode) {
      this.step = Step.SMS_VERIFICATION;
    } else {
      const role = r.body!.role;
      const token = r.headers.get('X-Auth-Token') as string;
      const rememberMe = r.headers.get('X-REMEMBER-ME') as string;
      this.auth.setToken(token, rememberMe);
      this.auth.setRole(role);
      this.router.navigate(['home']).then();
    }
  }

  public back(): void {
    this.step = Step.LOGIN;
  }

  get steps() {
    return Step;
  }
}
