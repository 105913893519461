<div class="form-group {{wrapperClass}}">
  <label *ngIf="label">
    {{label | translate}}
    <span *ngIf="required$ | async">*</span>
  </label>
  <ng-container [ngSwitch]="type">

    <ng-container *ngSwitchCase="'textarea'">
      <textarea
        [placeholder]="placeholder | translate"
        [formControl]="control"
        [attr.disabled]="disabled"
        class="form-control" #inputRef>
      </textarea>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <input [type]="type"
             [placeholder]="placeholder | translate"
             [attr.disabled]="disabled"
             [formControl]="control"
             class="form-control">
    </ng-container>

    <validation-error *ngIf="validation" [control]="control"></validation-error>

  </ng-container>
</div>

