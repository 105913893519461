import {FormControl} from '@angular/forms';
import {AppFormGroup} from '@core/form/AppFormGroup';
import {notEmpty} from '@core/form-validators/notEmpty';
import {email} from '@core/form-validators/email';

const buildControls = () => ({
  email: new FormControl('', [notEmpty(), email()]),
  password: new FormControl('', [notEmpty()]),
  rememberMe: new FormControl(false),
  code: new FormControl()
});

type Controls = ReturnType<typeof buildControls>;

export class LoginFormGroup extends AppFormGroup<Controls> {

  constructor() {
    super(buildControls());
  }
}
