import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {AppFormGroup} from '../../form/AppFormGroup';
import {IValidatorInfo} from '@core/components/validation-error/IValidatorPayload';

@Component({
  selector: 'validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss']
})
export class ValidationErrorComponent implements AfterViewInit {

  @Input()
  public control?: AbstractControl;

  constructor(private elRef: ElementRef) {
  }

  public shouldShowErrors(): boolean {
    return !!(this.control && this.control.errors && this.control.touched);
  }

  public listOfErrors(): IValidatorInfo[] {
    if (!this.control) {
      return [];
    }
    const errors = this.control.errors as { [key: string]: any };
    const error = Object.keys(errors)[0] as string;
    return [errors[error]];
  }

  private getRootForm(): FormGroup {
    let control = this.control as AbstractControl;
    while (control?.parent) {
      control = control.parent;
    }
    return control as FormGroup;
  }

  private setHtmlElement(element: HTMLDivElement): void {
    const rootForm = this.getRootForm();
    if (rootForm instanceof AppFormGroup && this.control) {
      rootForm.registerControlHtmlElement(this.control, element);
    }
  }

  public ngAfterViewInit(): void {
    this.setHtmlElement(this.elRef.nativeElement);
  }
}
