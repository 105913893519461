import {Component, Inject} from '@angular/core';
import {INotificationDetails} from '../../interfaces/INotificationDetails';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './notification-modal.component.html'
})
export class NotificationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<NotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INotificationDetails
  ) {
  }

}
