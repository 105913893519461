import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class SessionTimeoutInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => {
      }, (e: HttpErrorResponse) => {
        if (e.status === 403) {
          this.router.navigate(['login']);
        }
      })
    );
  }

}
