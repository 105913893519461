import {AbstractControl} from '@angular/forms';
import {isEmpty} from '@core/form/isEmpty';
import {IValidatorResult} from "@core/form-validators/IValidatorResult";

export const notEmpty = (message: string = 'errors.required') => (control: AbstractControl): IValidatorResult | null => {
  return !isEmpty(control.value) ? null : {
    required: {
      message
    }
  };
};
