import {BehaviorSubject, Observable, ReplaySubject, Subject, throwError} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {catchError, takeUntil} from 'rxjs/operators';
import {AppFormGroup} from '../form/AppFormGroup';
import {skipSessionTimeoutError} from '../helpers/skipSessionTimeoutError';
import {AppError} from '@core/models/AppError';

export type Action<P = any, R = any> = (data: P) => Observable<R>;

interface ISubmitterParams<P, R> {
  action: Action<P, R>;
  form: AppFormGroup<any>;
  errorMapper?: (e: HttpErrorResponse) => AppError<any, any>;
}

export class FormSubmitter<P = any, R = any> {

  private readonly destroy = new Subject();
  private readonly submitting = new BehaviorSubject<boolean>(false);
  private readonly error = new ReplaySubject<any>(1);
  private readonly success = new ReplaySubject<R>(1);

  public readonly submitting$ = this.submitting.asObservable();
  public readonly success$: Observable<R> = this.success.asObservable();
  public readonly error$: Observable<HttpErrorResponse> = this.error.asObservable();

  constructor(
    protected params: ISubmitterParams<P, R>
  ) {
  }

  public submit(p: P): void {
    const {form, action} = this.params;
    if (form.invalid) {
      form.showErrors();
      return;
    }
    this.submitting.next(true);
    action(p)
      .pipe(
        skipSessionTimeoutError(),
        catchError(e => {
          const {form, errorMapper} = this.params;
          this.error.next(e);
          form.handleError(e);
          const error = !form.invalid && errorMapper ? errorMapper(e) : e;
          return throwError(error)
        }),
        takeUntil(this.destroy)
      )
      .subscribe(
        r => this.onSuccess(r as R)
      )
      .add(() => {
        this.submitting.next(false);
      });
  }

  protected onSuccess(response: R): void {
    this.params.form.markAsPristine();
    this.success.next(response);
    this.error.next(null);
  }

  public dispose(): void {
    this.destroy.next();
    this.destroy.complete();
    this.error.complete();
    this.success.complete();
    this.submitting.complete();
    this.params.form.dispose();
  }

}
