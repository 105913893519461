<div class="wrapper">

  <img class="logo" alt="Logo" src="/assets/logo.svg">

  <ng-container [ngSwitch]="step">

    <ng-container *ngSwitchCase="steps.LOGIN">
      <login-first-step [form]="form" (successEvent)="onStepSubmit($event)"></login-first-step>
    </ng-container>

    <ng-container *ngSwitchCase="steps.SMS_VERIFICATION">
      <login-second-step [form]="form" (successEvent)="onStepSubmit($event)" (backEvent)="back()"></login-second-step>
    </ng-container>

  </ng-container>

  <div class="copyright">
    © {{currentYear}} CareOregon
  </div>
</div>
