import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Auth} from '@core/providers/auth/authProvider';
import {FormSubmitter} from '@core/misc/FormSubmitter';
import {ILoginPayload} from '@core/providers/auth/ILoginPayload';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {genericErrorMessage} from '@core/helpers/genericError';
import {ILoginResponse} from '@core/providers/auth/ILoginResponse';
import {HttpResponse} from '@angular/common/http';
import {LoginFormGroup} from "../LoginFormGroup";

@Component({
  selector: 'login-first-step',
  templateUrl: './login-first-step.component.html',
  styleUrls: ['./login-first-step.component.scss']
})
export class LoginFirstStepComponent implements OnInit, OnDestroy {

  public submitter!: FormSubmitter<ILoginPayload, HttpResponse<ILoginResponse>>;
  public loginError!: Observable<string>;

  @Output()
  public readonly successEvent = new EventEmitter<HttpResponse<ILoginResponse>>();

  @Input()
  public form!: LoginFormGroup;

  constructor(
    private router: Router,
    private api: Auth,
  ) {

  }

  public ngOnInit(): void {
    this.submitter = new FormSubmitter({
      action: p => this.api.login(p),
      form: this.form
    });
    this.submitter.success$.subscribe(r => this.successEvent.emit(r));
    this.loginError = this.submitter.error$.pipe(
      filter(e => !!e),
      map(e => {
        if (e.status === 401) {
          return 'pages.login.errors.wrongPassOrEmail';
        } else {
          return genericErrorMessage;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.submitter.dispose();
  }

  public login(): void {
    this.submitter.submit(this.form.value);
  }
}
