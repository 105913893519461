export const replacePayload = <TPayload>(validator: (...args: any[]) => any, replacer: (payload: any) => TPayload) => {
  return (...args: any[]) => {
    const result = validator(...args);
    if (result !== null) {
      const key = Object.keys(result)[0];
      return {
        [key]: replacer(result[key])
      };
    }
    return result;
  };
};
