import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AppError} from '@core/models/AppError';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(
    private ngzone: NgZone,
    private dialog: MatDialog
  ) {
  }

  public handleError(error: any): void {
    if (error instanceof AppError) {
      this.ngzone.run(() => {
        this.dialog.open(error.component, {
          disableClose: true,
          position: {top: '50px'},
          data: error.payload
        });
      });
    }
    console.error(error);
  }
}
