import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

export const skipSessionTimeoutError = () => (source: Observable<any>) =>
  source.pipe(
    catchError((e: HttpErrorResponse) => {
      const shouldSkip = e.status === 403;
      if (shouldSkip) {
        return EMPTY;
      } else {
        return throwError(e);
      }
    }),
  );
