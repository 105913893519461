import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {SimpleChanges} from '@core/interfaces/SimpleChanges';
import {ReplaySubject} from 'rxjs';

@Component({
  selector: 'required-star',
  template: `<span *ngIf="required | async">*</span>`,
  styleUrls: ['./required-star.component.scss']
})
export class RequiredStarComponent implements OnChanges, OnDestroy {

  public readonly required = new ReplaySubject<boolean>(1);

  @Input()
  public control?: AbstractControl;

  public ngOnChanges(changes: SimpleChanges<RequiredStarComponent>): void {
    if (changes.control && changes.control.currentValue && changes.control.previousValue !== changes.control.currentValue) {
      const control = changes.control.currentValue;
      if (control.validator) {
        const errors = control.validator({value: ''} as any);
        this.required.next(!!(errors && !!errors.required));
      }
    }
  }

  public ngOnDestroy(): void {
    this.required.complete();
  }

}
