import {AbstractControl} from '@angular/forms';

export const removeControlError = (errorKey: string, control: AbstractControl) => {
  if (control.errors) {
    const currentErrors = {...control.errors};
    delete currentErrors[errorKey];
    const hasNoErrors = Object.keys(control.errors).filter(k => k !== errorKey).length === 0;
    if (hasNoErrors) {
      control.setErrors(null);
    } else {
      control.setErrors(currentErrors);
    }
  }
};

