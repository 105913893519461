<form [formGroup]="form">

  <div class="rect"></div>

  <div class="heading">
    <div>{{'pages.login.firstStep.title' | translate}}</div>
    <div>{{'pages.login.firstStep.subTitle' | translate}}</div>
  </div>

  <div class="fields">

    <div class="login-error mb-2" *ngIf="loginError | async as error">
      {{error | translate}}
    </div>

    <mat-form-field class="w-100" appearance="standard">
      <mat-label>{{'pages.login.form.email' | translate}}</mat-label>
      <input matInput [formControl]="form.controls.email" autocomplete="username">
      <mat-icon matSuffix fontIcon="icon-mail"></mat-icon>
    </mat-form-field>
    <validation-error [control]="form.controls.email"></validation-error>

    <mat-form-field class="w-100 mt-4" appearance="standard">
      <mat-label>{{'pages.login.form.password' | translate}}</mat-label>
      <input matInput
             [formControl]="form.controls.password"
             type="password"
             autocomplete="current-password">
      <mat-icon matSuffix fontIcon="icon-lock"></mat-icon>
    </mat-form-field>
    <validation-error [control]="form.controls.password"></validation-error>

    <button mat-raised-button color="main" class="signInBtn"
            [disabled]="submitter.submitting$ | async"
            [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--default': submitter.submitting$ | async}"
            (click)="login()">
      {{'pages.login.signInBtn' | translate}}
    </button>

    <div class="row no-gutters justify-content-between footer">
      <div class="col-auto">
        <mat-checkbox color="primary" [formControl]="form.controls.rememberMe">
          {{'pages.login.form.rememberMe' | translate}}
        </mat-checkbox>
      </div>
    </div>

  </div>

</form>
