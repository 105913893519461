import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatButtonModule} from "@angular/material/button";
import {MatRippleModule} from "@angular/material/core";
import {MatDialogModule} from "@angular/material/dialog";
import {NotificationModalModule} from "@core/components/notification-modal/notification-modal.module";
import {ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ValidationErrorModule} from "@core/components/validation-error/validation-error.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormControlModule} from "@core/components/form-control/form-control.module";
import {RequiredStarModule} from "@core/components/required-star/required-star.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {AppErrorHandler} from "@core/AppErrorHandler";
import {LoginComponent} from "./pages/login/login.component";
import {authProvider} from "@core/providers/auth/authProvider";
import {SessionTimeoutInterceptor} from "@core/interceptors/sessionTimeout.interceptor";
import {AuthInterceptor} from "@core/interceptors/auth.interceptor";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import en from '../assets/i18n/en';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import * as Highcharts from 'highcharts';
import {AuthService} from "@core/services/auth.service";
import {AuthGuard} from "@core/guard/auth.guard";
import {NgxMaskModule} from "ngx-mask";
import {profileProvider} from "@core/providers/profile/profileProvider";
import {CodeInputModule} from "angular-code-input";
import {LoginFirstStepComponent} from "./pages/login/login-first-step/login-first-step.component";
import {LoginSecondStepComponent} from "./pages/login/login-second-step/login-second-step.component";

declare var require: any;
require('highcharts/highcharts-more')(Highcharts);

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatRippleModule,
    MatDialogModule,
    NotificationModalModule,
    ReactiveFormsModule,
    HttpClientModule,
    ValidationErrorModule,
    MatCheckboxModule,
    FormControlModule,
    RequiredStarModule,
    MatSnackBarModule,
    TranslateModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMaskModule.forRoot(),
    CodeInputModule
  ],
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    LoginFirstStepComponent,
    LoginSecondStepComponent,
  ],
  providers: [
    AuthService,
    AuthGuard,
    {provide: ErrorHandler, useClass: AppErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: SessionTimeoutInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    authProvider,
    profileProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translationService: TranslateService) {
    translationService.setTranslation('en', en, true);
    translationService.setDefaultLang('en');
    translationService.use('en');
  }
}
