import {NgModule} from '@angular/core';
import {ValidationErrorComponent} from './validation-error.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  declarations: [
    ValidationErrorComponent
  ],
  exports: [
    ValidationErrorComponent
  ]
})
export class ValidationErrorModule {
}
