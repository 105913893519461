import {Injectable} from '@angular/core';

const authTokenKey = 'auth';
const rememberMeTokenKey = 'remember';
const roleKey = 'role';

@Injectable()
export class AuthService {

  public setToken(auth: string, remember?: string): void {
    if (auth) {
      localStorage.setItem(authTokenKey, auth);
    }
    if (remember) {
      localStorage.setItem(rememberMeTokenKey, remember);
    }
  }

  public getToken(): { auth: string | null, remember?: string | null } | null {
    const authToken = localStorage.getItem(authTokenKey);
    if (!authToken) {
      return null;
    }
    return {
      auth: authToken,
      remember: localStorage.getItem(rememberMeTokenKey),
    };
  }

  public setRole(role: string) {
    localStorage.setItem(roleKey, role);
  }

  public getRole(): string {
    return localStorage.getItem(roleKey) || '';
  }

  public get isLoggedIn(): boolean {
    return !!this.getToken();
  }

  public clear(): void {
    localStorage.removeItem(authTokenKey);
    localStorage.removeItem(rememberMeTokenKey);
    localStorage.removeItem(roleKey);
  }
}
