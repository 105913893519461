import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {FormControl, NgControl} from '@angular/forms';
import {ControlWrapper} from '../../form/ControlWrapper';
import {ReplaySubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent extends ControlWrapper implements OnInit, AfterViewInit, OnDestroy {

  private readonly destroy = new Subject();
  public readonly required$ = new ReplaySubject<boolean>();

  @Input()
  public label?: string;

  @Input()
  public type?: 'text' | 'number' | 'textarea' | 'password' = 'text';

  @Input()
  public validation: boolean = true;

  @ViewChild('inputRef')
  public input!: ElementRef<HTMLInputElement | HTMLTextAreaElement>;

  @Input()
  public inputAttrs: { [key: string]: string | number } = {};

  @Input()
  public disabled?: boolean;

  @Input()
  public placeholder: string = '';

  @Input()
  public wrapperClass?: string;

  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl
  ) {
    super();
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    this.markAsRequired();
    this.ngControl?.control?.statusChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe(_ => this.markAsRequired());
  }

  private setAttributes() {
    const element = this.input?.nativeElement;
    for (const key of Object.keys(this.inputAttrs)) {
      element.setAttribute(key, String(this.inputAttrs[key]));
    }
  }

  private markAsRequired() {
    const control = this.ngControl.control;
    if (control?.validator) {
      const errors = control.validator({value: ''} as any);
      this.required$.next(!!errors?.required);
    }
  }

  public ngAfterViewInit(): void {
    this.setAttributes();
    this.markAsRequired();
  }

  public ngOnDestroy(): void {
    this.required$.complete();
    this.destroy.next();
    this.destroy.complete();
  }

  public get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

}
