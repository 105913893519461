import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationModalComponent} from './notification-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {PipesModule} from '@core/pipes/pipes.module';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    PipesModule,
    MatButtonModule,
    TranslateModule.forChild()
  ],
  declarations: [NotificationModalComponent],
  exports: [NotificationModalComponent]
})
export class NotificationModalModule {
}
