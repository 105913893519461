<form [formGroup]="form">

  <div class="rect"></div>

  <div class="heading">
    <div>{{'pages.login.secondStep.title' | translate}}</div>
    <div [innerHTML]="'pages.login.secondStep.subTitle' | translate"></div>
  </div>

  <div class="fields">

    <div class="login-error mb-2" *ngIf="loginError | async as error">
      {{error | translate}}
    </div>

    <code-input [isCodeHidden]="false"
                [codeLength]="6"
                (codeCompleted)="onCodeCompleted($event)">
    </code-input>

    <div class="row no-gutters justify-content-between footer">
      <div class="col-auto">
        <button class="backBtn" color="main" mat-raised-button (click)="backEvent.emit()">
          {{'pages.login.backBtn' | translate}}
        </button>
      </div>
    </div>

  </div>

</form>
