import {FactoryProvider} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, ReplaySubject} from "rxjs";
import {environment} from "@env/environment";
import {tap} from "rxjs/operators";
import {IProfile} from "./IProfile";
import {IUpdateProfilePayload} from "./IUpdateProfilePayload";

export class Profile {

  private readonly currentUser = new ReplaySubject<IProfile>(1);

  public readonly currentUser$: Observable<IProfile> = this.currentUser.asObservable();

  constructor(
    private http: HttpClient
  ) {
  }

  public get(): Observable<IProfile> {
    return this.http.get<IProfile>(`${environment.apiUrl}/profile`).pipe(
      tap(r => this.currentUser.next(r))
    );
  }

  public update(p: IUpdateProfilePayload): Observable<IProfile> {
    return this.http.patch<IProfile>(`${environment.apiUrl}/profile`, p).pipe(
      tap(r => this.currentUser.next(r))
    );
  }
}

export const profileProvider: FactoryProvider = {
  provide: Profile,
  useFactory: (http: HttpClient) => new Profile(http),
  deps: [HttpClient]
};
