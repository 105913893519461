import {ComponentType} from '@angular/cdk/overlay';

export class AppError<C, P> {
  public readonly component!: ComponentType<C>;
  public readonly payload!: P;

  constructor(data: Partial<AppError<C, P>>) {
    Object.assign(this, data);
  }
}
